.cookie-consent-banner {
  .cookie-title {
    font-size: $font-size-base * 1.125;
    font-weight: $font-weight-bold;
  }

  .cookie-consent-banner a:hover {
    text-decoration: none;
  }

  .cookie-btn {
    @extend .btn;
    @extend .btn-primary;

    &:hover {
      background-color: $white;
      border-color: $white;

      color: $black;
    }
  }
}

.cookie-consent {
  .row {
    margin: 0 -15px -30px -15px;
    
    > div {
      margin: 0 0 30px 0;
    }
  }

  .list {
    padding: 15px;
    
    &:hover {
      background-color: lighten($primary, 30%);
      border-color: $primary;
    }

    &::before {
      color: $primary;
    }
  }

  .active {
    .list {
      background-color: lighten($primary, 30%);
      border-color: $primary;
    }
  }

  .cookie-title {
    font-size: $font-size-base * 1.125;
  }

  .cookie-list {
    ul {
      margin: 0;
      padding: 0;

      list-style: none;

      li {
        position: relative;
        color: $gray-500;

        &::before {
          @extend .position-absolute;
          left: 0;
  
          @extend .d-block;
  
          font-family: 'Font Awesome 5 Pro';
          font-size: inherit;
          line-height: inherit;
          color: inherit;
  
          content: '\f057';
        }

        &.checked {
          color: $primary;

          &::before {
            content: '\f058';
          }
        }
      }
    }
  }

  .cookie-btn {
    @extend .btn;
    @extend .btn-primary;
    @extend .mt-3;
  }
}