&.service_category {
	.service_category_detail {
		.info {
			@include media-breakpoint-up(lg) {
				@include make-col(12);
			}

			@extend .mb-0;
			@extend .pr-lg-0;
		}

		.info-extra {
      @extend .d-none;
      visibility: hidden;
		}
	}
}