&.stip_de_pony_ranch {

	background: #cdb496;

	h1, h2, h3, h4, h5, h6 {
		color: #b66f51;
	}

	a:not(.btn):not(.card-btn) {
		color: #7ca98a;

		&:hover {
			color: #63876e;
		}
	}

	.btn {
		&.btn-primary {
			background-color: #7ca98a !important;
			border-color: #7ca98a !important;

			&:hover {
				background-color: #63876e !important;
				border-color: #63876e !important;
			}
		}
	}

	.owl-dots {
		.owl-dot {
			span {
				border-color: #7ca98a !important;
			}
			&.active {
				span {
					background: #7ca98a !important;
				}
			}
			&:hover {
				span {
					background: #63876e !important;
					border-color: #63876e !important;
				}
			}
		}
	}

	.container {
		@include media-breakpoint-down(xs) {
			padding: 0 15px;
		}
	}

	// extend
	.white-bg {
		max-width: 1140px;
		@include media-breakpoint-down(lg) {
			max-width: 960px;
		}
		@include media-breakpoint-down(md) {
			max-width: 720px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 540px;
		}
		@include media-breakpoint-down(xs) {
			margin-left: 15px;
			margin-right: 15px;
		}
		margin: 60px auto 60px auto;
		background: $white;
		border-radius: 4px;
		box-shadow: 0 0 6px rgba($black, 0.15);
		padding: 15px;
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	.heading {
		margin-bottom: 30px;
		text-align: center;
		/*margin: -45px auto 30px auto;
		padding: 4px 30px;
		background: #b66f51;
		border: 1px dotted $black;
		box-shadow: 0 3px 6px rgba($black, 0.15);
		color: $white;
		font-size: 28px;
		text-align: center;
		text-shadow: 0 3px 6px rgba($black, 0.15);
		transform: rotateZ(-1.5deg);*/
	}

	// header
	.header {
		position: relative !important; /* override */
		padding-top: 60px;
		box-shadow: none; /* override */

		.header-main {
			background: transparent; /* override */
		}
		h1 {
			margin-bottom: 0;
			color: $white;
			font-size: 36px;
			text-align: center;
			text-shadow: 0 3px 6px rgba($black, 0.15);
		}
		.resource_image {
			text-align: center;
			img {
				margin: 0 auto;
			}
		}
	}

	// intro-section
	.intro-section {
		@extend .white-bg;
		text-align: center;
		.container-holder {
			max-width: 760px;
			margin: 0 auto;
		}
	}

	// collection-section
	.collection-section {
		margin: 60px 0;
		.card {
			background: $white;
			border-radius: 4px;
			box-shadow: 0 0 6px rgba($black, 0.15);
			.card-body {
				.card-caption {
					.card-title,
					.card-title-link {
						color: #b66f51;
					}
				}
				.card-buttons {
					.card-btn {
						background: #7ca98a;
						border-color: #7ca98a;
						border-radius: 4px;
						font-size: 16px;
						font-weight: 400;
						text-transform: none;
						&:hover {
							background: #63876e;
							border-color: #63876e;
						}
						&:focus {
							box-shadow: 0 0 0 0.2rem rgba(#63876e, 0.25);
						}
					}
				}
			}
		}
	}

	// video-section
	.video-section {
		@extend .white-bg;
		.video {
			position: relative;
			padding-bottom: 56.25%;
			.column {
				position: unset;
			}
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 4px;
			}
		}
	}

	// content-section
	.content-section {
		@extend .white-bg;
	}

	// footer
	.footer {
		position: relative;
		margin-top: calc(60px + 3vh);
		padding: 3vh 0;
		background: #b66f51;
		color: $white;
		text-align: center;
		a {
			color: $white !important;
			text-decoration: underline;
			&:hover {
				color: #000 !important;
			}
		}
		&::before {
			content: "";
			position: absolute;
			bottom: 100%;
			right: 0;
			width: 100%;
			height: 3vh;
			border-left: 100vw solid transparent;
			border-right: 0 solid transparent;
			border-bottom: 3vh solid #b66f51;
		}
	}
}
