.btn {
	border-radius: 25px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: 22px;
	font-weight: 700;

	@include media-breakpoint-up(xl) {
		padding-left: 45px;
		padding-right: 45px;
	}

	// btn-primary
	&.btn-primary {
		color: $white;

		&:hover {
			background-color: $orange;
			border-color: $orange;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		color: $white;

		&:hover {
			background-color: $pink;
			border-color: $pink;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}

}

// btn-back
.btn-back {
	text-decoration: none;
}
