.vc-popover-content-wrapper {
	left: 0 !important;
	right: auto !important;
	display: block !important;

	@media only screen and (max-width: 1600px) {
		right: 0 !important;
		left: auto !important;

	}
}
