.footer {

	// footer-top
	.footer-top {
		padding: 6vh 0;
		background: $blue-light;

		.container-three-columns {
			.column {
				display: flex;
				flex-direction: column;

				&.one {
					@include media-breakpoint-up(sm) {
						@include make-col(6);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}

				&.two {
					@include media-breakpoint-up(sm) {
						@include make-col(6);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(5);
					}
				}

				&.three {
					@include make-col(12);

					@include media-breakpoint-down(md) {
						order: -1;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}
				}
			}
		}

		// quick-contact-button
		.quick-contact-button {
			margin-bottom: 30px;

			@include media-breakpoint-down(lg) {
				display: none;
			}

			p {
				margin-bottom: 0;
			}

			a {

				&[href^='tel:'],
				&[href^='mailto:'] {

					position: relative;
					border-radius: 20px;
					padding: 12px 20px 12px 70px;
					font-size: 18px;
					font-weight: 400;
					text-transform: uppercase;
					font-family: $font-family-secondary;

					&::before {
						position: absolute;
						left: 25px;
						color: $white;
						font-family: $font-awesome;
					}

				}

				&[href^='tel:'] {
					&::before {
						content: '\f879';
						top: 6px;
						font-size: 26px;
						font-weight: 900;
					}
				}

				&[href^='mailto:'] {
					&::before {
						content: '\f0e0';
						top: 4px;
						font-size: 30px;
						font-weight: 300;
					}
				}
			}
		}

		// footer_list
		.footer_list {
			margin-bottom: 30px;

			h5 {
				color: $blue-dark;
				font-size: 26px;
				text-transform: uppercase;
			}

			ul,
			.list {
				margin: 0;
				padding: 0;
				list-style: none;

				li,
				.list-item {

					a,
					.link {
						color: $black;
						text-decoration: none;

						&:hover {
							color: $pink;
						}
					}
				}
			}
		}

		// footer_logolink
		.footer_logolink {
			margin-top: auto;

			@include media-breakpoint-down(sm) {
				margin-bottom: 15px;
			}

			ul,
			.list {
				align-items: center;

				li,
				.list-item {

					a,
					.link {
						display: block;
						width: 60px;
						height: 60px;
						background-color: $orange;
						border-radius: 20px;
						color: $white;
						font-size: 36px;
						line-height: 60px;
						text-align: center;

						i {
							line-height: 60px;
						}

						&:hover {
							background-color: $pink;
						}
					}
				}
			}
		}

		// pay-safe-with
		.pay-safe-with {
			margin-top: auto;

			p:last-of-type {
				margin-bottom: 0;
			}
		}

		// stay-informed
		.stay-informed {
			height: 100%;
			border-radius: 20px;
			background: $white;
			padding: 20px;
			text-align: center;

			@include media-breakpoint-down(md) {
				margin-bottom: 30px;
			}

			@include media-breakpoint-up(xl) {
				padding: 40px;
			}

			p:last-of-type {
				margin-bottom: 0;
			}

			img {
				max-width: 280px;
				margin: 0 auto;
			}
		}
	}

	// footer-bottom
	.footer-bottom {
		padding: 30px 0;
		background: $white;
		text-align: center;

		ul,
		.list {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}

			li,
			.list-item {
				margin: 0 10px;

				a,
				.link {
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
