.card {
	.card-image {
		overflow: hidden;

		.card-image-caption {
			top: 0;

			@extend .w-100;
			@extend .h-100;

			opacity: 0;
			color: #ffffff;

			transition: $transition-base;

			& * {
				color: $white;
			}

			ul {
				padding: 0;
				list-style: none;

				li {
					@extend .position-relative;
					margin: 0 0 7px 0;
					padding: 0 0 0 30px;

					font-size: $font-size-base * 0.875;
					font-weight: $font-weight-semi-bold;
					line-height: 18px;

					&::before {
						@extend .position-absolute;
						left: 0;

						font-family: "Font Awesome 5 Pro";
						font-size: inherit;
						font-weight: $font-weight-base;
						line-height: inherit;
						color: inherit;

						content: "\f00c";
					}
				}
			}
		}

		&:hover {
			.card-image-caption {
				opacity: 1;
			}
		}
	}

	.card-body {
		.card-caption {
			.card-description {
				min-height: auto;
				max-height: none;

				&::after {
					@extend .d-none;
				}
			}
		}
	}
}

&.two_column_page,
&.landings_page,
&.bundle-overview,
&.bundle-category {
	.card {
		background-color: $white;

		.card-body {
			.card-caption {
				.card-title {
					font-size: 20px;
					text-transform: uppercase;
				}
			}

			.card-buttons {
				.card-btn {
					@extend .btn-secondary;
				}
			}
		}
	}
}

&.one_column_page {
	.content {
		.card.default {
			background-color: $white;
		}
	}
}

/* card > card-arrangement
   ========================================================================== */
.card.card-arrangement {

	.card-prices,
	.card-traveldata {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

/* card > card-offer
   ========================================================================== */
.card.card-offer,
.home-blocks .card {
	background: $white;
	border-radius: 20px;

	.card-image {
		padding-top: 100%;

		.card-image-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.card-img {
			height: 100%;
			object-fit: cover;
		}
	}

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 30px 30px 45px 30px;
		}

		.card-caption {
			.card-title-link {
				color: $green;

				.card-title {
					color: inherit;
					font-size: 24px;
					text-transform: uppercase;
				}

				&:hover {
					color: $pink;
				}
			}

			.card-description {
				&::after {
					display: none;
				}
			}

			.card-price {
				margin-top: 15px;
			}

			.card-price {
				color: $blue;
				font-weight: 700;
			}
		}

		.card-buttons {
			.card-btn {
				@extend .btn-secondary;
			}
		}
	}
}

/*  accommodations-blocks
    ========================================================================== */
.accommodations-blocks {
	.slider .owl-item .item {
		@include media-breakpoint-up(md) {
			height: 100% !important;
		}
	}

	.card {
		.card-image {
			margin-bottom: 15px;
			background: $white;
			border-radius: 50% 50% 50% 50% / 42% 50% 50% 58%;
			aspect-ratio: 1;
			overflow: hidden;

			.card-image-link {
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
					transition: 0.5s;
				}

				&:hover {
					img {
						transform: scale(1.05);
					}
				}
			}
		}

		.card-body {
			padding: 0 15px;

			.card-caption {
				.card-title-link {
					color: $beige;

					.card-title {
						color: inherit;
						font-size: 22px;
						text-transform: uppercase;
						@extend .text-center;
					}
				}

				.card-description {
					@extend .text-center;
				}
			}

			.card-buttons {
				margin-top: 10px;
				text-align: center;

				.card-btn {
					@extend .btn-secondary;
					text-transform: capitalize;
				}
			}
		}
	}
}

/*  highlights
    ========================================================================== */
.highlights {
	.card {
		border-radius: 0;

		.card-image {
			border-radius: 15px
		}

		.card-body {
			padding: 15px;

			.card-caption {
				.card-title {
					@extend .text-center;
					text-transform: uppercase;
				}

				.card-description {
					min-height: auto;
					max-height: none;

					@extend .text-center;

					.list-icons {
						margin-top: 0;

						.list-item {
							padding-left: 0;

							.icon {
								display: none;
							}
						}
					}
				}

				.card-description::after {
					@extend .d-none;
				}
			}

			.card-buttons {
				@extend .d-none;
			}
		}
	}
}

/*  highlights
    ========================================================================== */
.highlights-2 {
	.card {
		margin-bottom: 30px;

		.card-image {
			margin: 0 30px 30px 30px;

			@include media-breakpoint-up(xl) {
				margin: 0 45px 30px 45px;
			}

			background: $white;
			border-radius: 50% 50% 50% 50% / 42% 50% 50% 58%;
			aspect-ratio: 1;
			overflow: hidden;

			.card-image-link {
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
					transition: 0.5s;
				}

				&:hover {
					img {
						transform: scale(1.05);
					}
				}
			}
		}

		.card-body {
			padding: 0;

			.card-caption {
				.card-title-link {
					margin-bottom: 15px;
					color: $orange;

					.card-title {
						color: inherit;
						font-size: 26px;
						text-decoration: underline;
						text-transform: uppercase;
						@extend .text-center;
					}

					&:hover {
						color: $pink;
					}
				}

				.card-description {
					min-height: auto;
					max-height: none;

					@extend .text-center;
				}

				.card-description::after {
					@extend .d-none;
				}
			}

			.card-buttons {
				@extend .d-none;
			}
		}
	}
}

/*  intro-block cards
    ========================================================================== */
.intro-block {
	.card {
		.card-image {
			margin-bottom: 15px;
			background: $white;
			border-radius: 50% 50% 50% 50% / 42% 50% 50% 58%;
			aspect-ratio: 1;
			overflow: hidden;
			max-width: 265px;
			margin-left: auto;
			margin-right: auto;
			img {
				height: 100%;
				object-fit: cover;
				transition: 0.5s;
			}
			&:hover {
				img {
					transform: scale(1.05);
				}
			}

		}

		.card-body {
			padding: 0 15px;
			.card-caption {

				.card-title {
					max-width: 220px;
					margin-left: auto;
					margin-right: auto;
					color: $blue;
					font-size: 22px;
					text-transform: uppercase;
					@extend .text-center;
				}

				.card-description {
					@extend .text-center;

					ul {
						@extend .list-unstyled;
						li {
							position: relative;
							max-width: max-content;
							margin: auto;
							&::before {
								content: '';
								position: absolute;
							    top: 0px;
								left: -20px;
								width: 18px;
								height: 18px;
								background-image: url('/images/check.svg');
								background-repeat: no-repeat;
								background-size: contain;
							}
						}
					}
				}
			}

			.card-buttons {
				margin-top: 10px;
				text-align: center;

				.card-btn {
					@extend .btn-secondary;
					text-transform: capitalize;
				}
			}
		}
	}
}

/*  extra-block cards
    ========================================================================== */
	.extra-block {
		.card {
			.card-image {
				margin-bottom: 15px;
				background: $blue;
				border-radius: 50% 50% 50% 50% / 42% 50% 50% 58%;
				aspect-ratio: 1;
				overflow: hidden;
				max-width: 265px;
				margin-left: auto;
				margin-right: auto;
				img {
					height: 100%;
					object-fit: cover;
					transition: 0.5s;
				}
				&:hover {
					img {
						transform: scale(1.05);
					}
				}

			}

			.card-body {
				padding: 0 15px;
				.card-caption {

					.card-title {
						max-width: 220px;
						margin-left: auto;
						margin-right: auto;
						color: $blue;
						font-size: 22px;
						text-transform: uppercase;
						@extend .text-center;
					}

					.card-description {
						@extend .text-center;

						ul {
							@extend .list-unstyled;
							li {
								position: relative;
								max-width: max-content;
								margin: auto;
								&::before {
									content: '';
									position: absolute;
									top: 0px;
									left: -20px;
									width: 18px;
									height: 18px;
									background-image: url('/images/check.svg');
									background-repeat: no-repeat;
									background-size: contain;
								}
							}
						}
					}
				}

				.card-buttons {
					margin-top: 10px;
					text-align: center;

					.card-btn {
						@extend .btn-secondary;
						text-transform: capitalize;
					}
				}
			}
		}
	}
