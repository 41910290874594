// headings
h1,
h2 {
	text-transform: uppercase;
}

&.home {
	h2 {
		font-size: $h1-font-size;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
	border: 0;
}

// custom-file-input
.custom-file-input {
	opacity: 1;
	line-height: 38px;
	margin-left: 7px;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// slider
.slider {
	min-height: auto;

	.owl-item {
		.item {
			height: auto !important;
		}
	}

	.owl-dots {
		.owl-dot {
			span {
				width: 28px;
				height: 28px;
				margin: 7px;
				border-width: 3px !important;
			}

			&:hover,
			&.active {
				span {
					border-width: 3px !important;
				}
			}
		}
	}
}

// search_book
.search_book {
	.w3-filter-collection {
		padding: 20px;
		border-radius: 15px;
		background: $white;
		box-shadow: $box-shadow;

		@include media-breakpoint-up(lg) {
			padding: 30px;
			border-radius: 30px;
		}
	}
}

// accommodation_arrangement_overview
&.accommodation_arrangement_overview {
	.accommodation_arrangement {
		.card {
			.card-info {
				display: none;
			}
		}
	}
}

// w3-daterangepicker
&.home,
&.accommodation_search_book {
	.w3-vue-booking-wrapper .w3-daterangepicker .dp__main .dp__menu {
		@include media-breakpoint-down(md) {
			top: 56px !important;
			transform: translate(-50%, 0%) !important;
		}

		@media screen and (max-height: 711px) {
			top: 56px !important;
			transform: translate(-50%, 0) !important;
		}
	}
}

// two_column_page
&.two_column_page {
	.content + .content {
		padding-top: 0 !important;
	}
}

// global-usps
.global-usps {
	margin: 30px -5px;
	justify-content: center;

	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}

	li {
		margin: 5px;

		@include media-breakpoint-up(lg) {
			flex: 1;
			max-width: 290px;
		}

		a {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 10px 15px;
			background: $blue;
			border-radius: 5px;
			color: $white;
			text-decoration: none;

			i {
				margin-right: 15px;
				font-size: 24px;
			}

			&:hover {
				background: $blue-dark;
			}
		}
	}
}
