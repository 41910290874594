// mini-sab
.mini-sab {
	padding: 0;
	background-color: $blue-light;

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 998;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: -50px 0 0 0;
			padding: 20px;
			border-radius: 15px;
			background: $white;
			box-shadow: $box-shadow;
			text-align: center;

			@include media-breakpoint-up(lg) {
				margin-top: -60px;
				padding: 30px;
				border-radius: 30px;
			}

			/*
			&::before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 100%;
				width: 246px;
				height: 190px;
				margin: 0 auto -35px auto;
				background: url('/images/fien-en-teun-kiekeboe.png');
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
			*/

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $orange;
			}

			p:last-of-type {
				margin-bottom: 0;
			}

			.btn {
				font-family: $font-family-secondary;
			}
		}
	}
}
