/* Container-two-columns-custom
   ========================================================================== */
   .container-two-columns-custom {
    .column.one {
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
    }
    .column.two {
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
  }