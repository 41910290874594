@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/base';
	@import './2_layout/containers';
	@import './2_layout/header';
	@import './2_layout/content';
	@import './2_layout/footer';
	@import './2_layout/mini-sab';
	@import './2_layout/plan-your-visit';
	@import './2_layout/eyecatcher';

	@import './3_components/buttons';
	@import './3_components/cards';
	@import './3_components/cookie-consent';
	@import './3_components/lists';
	@import './3_components/owl-carousel';
	@import './3_components/modal';

	@import './4_page_block/collections';
	@import './4_page_block/collections_custom';
	@import './4_page_block/contentblocks_custom';

	@import './6_theme/module.accommodations';
	@import './6_theme/module.faq';
	@import './6_theme/module.service';
	@import './6_theme/page.home';
	@import './6_theme/page.landing';

	@import './7_hacks/mini-zeb';
}

body.speelschuur {
	@import './6_theme/page.speelschuur';
}

body.stip_de_pony_ranch {
	@import './6_theme/page.stip_de_pony_ranch';
}

body.stoeterij {
	@import './6_theme/page.stoeterij';
}

@import './6_theme/tommy.book';
@import './6_theme/tommy.matrix';
