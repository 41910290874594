// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh !important; // overide
	min-height: 280px !important; // overide
	max-height: 540px;
	padding: unset; // overide
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: calc(75vh - 136px) !important; // overide
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 146px) !important; // overide
	}
}

// eyecatcher
.eyecatcher {
	background: $white;
	overflow: hidden;

	// vimeo-video
	.vimeo_video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	&::after {
		content: url('/images/eyecatcher-bg.svg');
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto -1px auto;
		font-size: 0;
		line-height: 0;
		aspect-ratio: 300/23;
	}

	&.large {
		@include media-breakpoint-down(sm) {
			min-height: $carousel-min_height;
		}
	}

	.owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.owl-container {
						.owl-caption {
							.owl-title {
								text-align: center;
								font-size: 2rem;
								text-shadow: none;
							}
						}
					}
				}
			}
		}
	}

	.owl-nav,
	.owl-dots {
		display: none;
	}
}
