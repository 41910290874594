.page_block {
  &.grid {
    .item {
      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
    }
  }
}

.blog_category_filter {
    .list {
		display: flex;
		flex-wrap: wrap;
        margin: 0 0 30px 0;
        padding: 0 !important;
        list-style: none !important;
		.list-item {
			margin: 0 15px 15px 0;
			.list-link {
				@extend .btn;
				@extend .btn-primary;
			}
		}
    }
}