&.landings_page {
	.content {
		padding: 6vh 0;

		h1 {
			text-align: center;
		}

		&:nth-child(2),
		&:nth-child(4),
		&:nth-child(6),
		&:nth-child(8),
		&:nth-child(10),
		&:nth-child(12),
		&:nth-child(14) {
			background-color: $beige-light;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $beige;
			}

			.slider {
				.owl-dots {
					.owl-dot {
						span {
							border-color: $beige;
						}

						&:hover,
						&.active {
							span {
								background-color: $beige;
								border-color: $beige;
							}
						}
					}
				}
			}

			.card .card-caption .card-title-link {
				color: $beige;
			}
		}

		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(7),
		&:nth-child(9),
		&:nth-child(11),
		&:nth-child(13),
		&:nth-child(15) {
			background-color: $green-light;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $green;
			}

			.slider {
				.owl-dots {
					.owl-dot {
						span {
							border-color: $green;
						}

						&:hover,
						&.active {
							span {
								background-color: $green;
								border-color: $green;
							}
						}
					}
				}
			}

			.card .card-caption .card-title-link {
				color: $green;
			}
		}
	}
}
