&.faq_overview {
	.faq-overview {
		.faq-category {
			.faq-title {
				padding-left: 0;
				pointer-events: none;

				.faq-icon {
					display: none !important;
				}
			}

			.faq-items {
				display: block !important;

				.faq-item {
					.faq-question {
						border-color: rgba($white, 1);

						h3 {
							font-family: $font-family-primary;
							font-weight: 400;
						}
					}

					&.active {
						.faq-question {
							h3 {
								font-weight: 700;
							}
						}
					}

					.faq-answer {
						border-color: rgba($white, 1);

						p {
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
