.list {
  .list-item {
    > .title {
      font-weight: bold;
    }
  }

  &.list-grid {
    @include make_row();

    margin: 0 -15px -15px -15px;

    .list-item {
      @include make-col-ready();
		  @include make-col(12);

      margin: 0 0 15px 0;
      padding: 0 15px;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
    }
  }
}


ol.custom-list {
	.list-item {
		&::before {
			content: "\f00c" !important;
			font-size: 1rem !important;
			color: $green !important;
			font-weight: 700 !important;
		}
	}
}
