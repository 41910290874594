&.speelschuur .notification-section,
&.stip_de_pony_ranch .notification-section {
	margin-top: -60px !important;
}
.notification-section {
	background: $green;
	@include media-breakpoint-down(md) {
		position: fixed;
		bottom: 0;
		z-index: 1000;
		left: 0;
		width: 100%;
	}
	.notice_bar {
		margin-top: -100vh;
		transition: 0.75s ease;
		opacity: 0;
		@include media-breakpoint-down(md) {
			margin-top: auto;
			margin-bottom: -100vh;
		}
	}
	.notice_bar.show {
		margin-top: 0;
		transition: 0.75s ease;
		opacity: 1;
		.notice-bar-content {
			color: $white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.5rem;
			gap: 1rem;
			.notice-bar-content-description {
				width: 95%;
				display: flex;
				justify-content: center;
				p {
					margin: auto !important;
					font-weight: 600;
				}
			}
			.notice-bar-close {
				.notice-bar-close-icon {
					color: #fff;
				}
				.notice-bar-close-title {
					display: none;
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 0;
		}
	}
	a {
		color: $white;
	}
}
