/*  intro
    ========================================================================== */
&.home {
	.intro {
		padding: 6vh 0;
	}
}

/*  home-blocks
    ========================================================================== */
&.home {
	.home-blocks {
		padding: 6vh 0;
	}
}

.home-blocks {
	padding: 6vh 0;
	background-color: $green-light;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $green;
	}

	.intro-text {
		margin-bottom: 4.5vh;
		text-align: center;
	}

	.outro-text {
		margin-top: 4.5vh;
		text-align: center;
	}

	.slider {
		.owl-dots {
			.owl-dot {
				span {
					border-color: $green;
				}

				&:hover,
				&.active {
					span {
						background-color: $green;
						border-color: $green;
					}
				}
			}
		}
	}
}

/*  accommodations-blocks
    ========================================================================== */
.accommodations-blocks {
	padding: 6vh 0;
	background-color: $beige-light;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $beige;
	}

	.intro-text {
		margin-bottom: 30px;
		text-align: center;
	}

	.slider {
		.owl-dots {
			.owl-dot {
				span {
					border-color: $beige;
				}

				&:hover,
				&.active {
					span {
						background-color: $beige;
						border-color: $beige;
					}
				}
			}
		}
	}
}

/*  home-double
    ========================================================================== */
&.home {
	.home-double {
		padding: 6vh 0;
	}
}

.home-double {
	position: relative;
	padding: 6vh 0;
	background: $blue-light;

	.container-two-columns {
		.column {
			&.one {
				@include media-breakpoint-up(xl) {
					@include make-col(7);
				}
			}

			&.two {
				@include media-breakpoint-up(xl) {
					@include make-col(5);
				}
			}
		}
	}
}

/*  highlights
    ========================================================================== */
.highlights {
	padding: 6vh 0;
	background: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $green;
	}

	.intro-text {
		margin-bottom: 45px;
		text-align: center;
	}

	.outro-text {
		margin-top: 45px;
		text-align: center;
	}

	.slider {
		.owl-dots {
			.owl-dot {
				span {
					border-color: $green;
				}

				&:hover,
				&.active {
					span {
						background-color: $green;
						border-color: $green;
					}
				}
			}
		}
	}
}

/*  highlights-2
    ========================================================================== */
.highlights-2 {
	padding: 6vh 0;
	background-color: $beige-light;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $beige;
	}

	.intro-text {
		margin-bottom: 45px;
		text-align: center;
	}

	.outro-text {
		margin-top: 45px;
		text-align: center;
	}

	.page_block.grid .item {
		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}

		@include media-breakpoint-down(sm) {
			@include make-col(6);
		}
	}
}

/*  intro-block
    ========================================================================== */
.intro-block {
	margin-top: 6vh;
	margin-bottom: 6vh;
}

/*  extra-block
    ========================================================================== */
.extra-block {
	padding-top: 6vh;
	padding-bottom: 6vh;
	background-color: $white;
}
