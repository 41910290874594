/* ==========================================================================
   body
   ========================================================================== */
$body-padding: 0;
$body-sticky-padding: 0;

/* ==========================================================================
   card
   ========================================================================== */
$card-background: transparent;
$card-border: none;
$card-base-border-radius: 10px;

/* card > card-image
   ========================================================================== */
$card-image-background: transparent;

/* card > card-image-caption */
$card-image-caption-padding: 25px 20px;
$card-image-caption-background: rgba($blue, 0.8);

/* card > card-body
   ========================================================================== */
$card-body-padding: 25px 20px;
$card-body-background: transparent;

/* card > card-caption
   ========================================================================== */
/* card > card-title */
$card-title-font_size: $font-size-base * 1.125;
$card-title-font_size_sm: $card-title-font_size;
$card-title-color: $blue;

/* card > card-description */
$card-description-fade-background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, 1) 100%);


/* ==========================================================================
   content
   ========================================================================== */

$content-padding: 6vh 0;
$content-h1-color: $headings-color;
$content-h2-color: $headings-color;
$content-h3-color: $headings-color;
$content-h4-color: $headings-color;
$content-h5-color: $headings-color;
$content-h6-color: $headings-color;
