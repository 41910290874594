.page_block {
	&.searchbox {
		margin: 0 0 15px 0;

		.form-control {
			border-color: $black;

			&:focus {
				border-color: $black;
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgba($green, .25);
			}
		}

		.search-icon {
			display: none;
		}
	}

	&.search_results {
		.search-item {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 30px 0;

			.search-term {
				@extend .btn;

				background: $gray-500;
				border: 1px solid $gray-500;

				user-select: auto;
				cursor: auto !important;
				color: $white;

				&:hover {
					background: $gray-500;
					border: 1px solid $gray-500;

					color: $white;
				}
			}
		}

		.search-alert {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 30px 0;
		}

		.card {
			background-color: $white;

			.card-body {
				.card-caption {
					.card-title {
						font-size: 20px;
						text-transform: uppercase;
					}
				}

				.card-buttons {
					.card-btn {
						@extend .btn-secondary;
					}
				}
			}
		}
	}
}
