	/*  content
    ========================================================================== */
	.content .resource_image {
		margin: 0 0 2rem 0;
	}

	/*  intro
    ========================================================================== */
	.intro {
		position: relative;
		padding: 6vh 0;
		background: $blue-light;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $blue;
		}

		a {
			color: $black;
			// text-decoration: underline;

			&:hover {
				color: $pink;
			}
		}

		.container-two-columns {
			.column {
				&.one {
					@include media-breakpoint-down(sm) {
						order: 1;
					}
					text-align: center;

					@include media-breakpoint-up(lg) {
						@include make-col(5);
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
					}
				}
			}
		}
	}
