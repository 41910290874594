// plan-your-visit-bar
.plan-your-visit-bar {
	background-color: $blue-light;

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 998;
			position: relative;
			margin: -50px 0 0 0;
			padding: 20px;
			border-radius: 15px;
			background: $white;
			box-shadow: $box-shadow;

			@include media-breakpoint-up(lg) {
				margin-top: -150px;
				padding: 30px;
				border-radius: 30px;
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 100%;
				width: 246px;
				height: 190px;
				margin: 0 auto -35px auto;
				background: url('/images/fien-en-teun-kiekeboe.png');
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			// nav-tabs
			.nav-tabs {
				justify-content: center;
				margin-bottom: 30px;
				border-bottom: none;

				.nav-item {
					font-size: 20px;
					text-transform: uppercase;
					font-family: $font-family-secondary;

					.nav-link {
						background-color: transparent;
						border-radius: none;
						border: none;
						border-bottom: 3px solid rgba($black, 0.15);
						color: $black;

						@include media-breakpoint-up(lg) {
							padding-left: 2rem;
							padding-right: 3rem;
						}

						&:hover {
							color: $pink;
							border-color: $pink;
						}

						&.active {
							color: $orange;
							border-color: $orange;
						}
					}
				}
			}

			// tab-content
			.tab-content {
				.tab-pane {
					position: relative;
					align-items: center;

					@include media-breakpoint-down(sm) {
						flex-direction: column;
						text-align: center;
					}

					@include media-breakpoint-up(md) {
						flex-flow: row wrap;
						justify-content: space-between;
					}

					@include media-breakpoint-up(lg) {
						min-width: 920px;
					}

					&.active {
						display: flex;
					}

					.w3media-booking-mini-searchbook-widget {
						width: 100%;

						.widget-btn {
							width: 100%;
						}
					}

					.text {
						@include media-breakpoint-down(sm) {
							margin-bottom: 1rem;
						}

						@include media-breakpoint-up(md) {
							margin-right: 30px;
						}

						h1,
						h2,
						h3,
						h4,
						h5,
						h5 {
							color: $black;
						}
					}

					.link {
						.btn {
							border-color: $pink;
							background-color: $pink;
							color: $white;
							font-weight: 400;
							font-family: $font-family-secondary;

							&:hover {
								border-color: $green;
								background-color: $green;
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}
