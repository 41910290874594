.header {
	position: sticky !important;
	top: 0;
	left: 0;
	right: 0;
	box-shadow: $box-shadow;

	// header-top
	.header-top {
		padding: 15px;
		background-color: $beige-light;

		.container {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.navbar {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			@extend .navbar-expand-xl;
			padding: 0;
		}

		// quick-socials
		.quick-socials {
			margin: 0 0 0 auto;

			li {
				margin-right: 0;
				font-size: 22px;

				+ li {
					margin-left: 10px;
				}

				a {
					color: $black;

					&:hover {
						color: $pink;
					}

					span {
						display: none;
					}
				}
			}
		}

		// searchbox
		.searchbox {
			position: relative;
			z-index: 20;
			margin: 0 0 0 20px;
			overflow: hidden;

			.form-control {
				position: relative;
				width: 0;
				right: -100%;
				transition: $transition-base;

				&.show {
					width: auto;
					right: 0;
				}
			}

			.search-icon {
				position: absolute;
				top: 0;
				right: 0;
				display: block;
				min-width: 34px;
				height: 34px;
				margin: 2px 0 2px 15px;
				line-height: 34px;
				text-align: center;
				transition: $transition-base;
				cursor: pointer;

				i {
					font-weight: 400;
				}

				&.hide {
					right: -100%;
				}

				&:hover {
					color: $pink;
				}
			}
		}

		// top-menu
		.top-menu {
			flex-grow: 0;

			.navbar-nav {
				margin-left: auto;

				>.nav-item {
					margin-left: 5px;
					font-size: 16px;

					>.nav-link {
						display: flex;
						align-items: center;
						color: $black;

						&:hover {
							color: $pink;
						}
					}

					&.active {
						>.nav-link {
							color: $pink;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border: none;
						box-shadow: $box-shadow;

						.nav-item {
							font-size: 15px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $pink;
								}
							}

							&.active {
								.nav-link {
									color: $pink;
								}
							}
						}
					}
				}
			}
		}

		// language
		.site_switcher {
			.navbar-nav {
				margin-left: 15px;

				.nav-item {
					.nav-link {
						display: flex;
						align-items: center;
						border: 1px solid $black;
						border-radius: 10px;
						padding: 0 0.5rem;
						color: $black;
						font-size: 18px;
						line-height: 32px;
						text-transform: uppercase;

						&::after {
							display: none;
						}

						.language-code {
							margin-right: 5px;
						}

						img {
							height: 16px;
						}

						&:hover {
							border-color: $pink;
							color: $pink;
						}
					}

					.dropdown-menu {
						position: absolute;
						min-width: 0;
						padding: 0;
						border-radius: 10px;
						border-color: $black;
						overflow: hidden;

						.dropdown-item {
							padding: 0.25rem 0.5rem;

							.language-code {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	// header-main
	.header-main {
		padding: 15px;
		background-color: $blue-light;

		.navbar {
			display: flex;
			align-items: center;
			justify-content: flex-end !important;
			padding: 0;
		}

		// logo
		.logo {
			z-index: 10;
			position: absolute;
			top: -73px;
			left: 15px;
			max-width: 115px;

			@include media-breakpoint-up(sm) {
				max-width: 150px;
			}

			@include media-breakpoint-up(xl) {
				max-width: 220px;
			}
		}

		// main-menu
		.main-menu {
			@include media-breakpoint-up(xl) {
				display: flex !important;
			}

			.navbar-nav {
				margin-left: auto;
				font-family: $font-family-secondary;

				>.nav-item {
					margin-left: 15px;
					font-size: 20px;

					>.nav-link {
						display: flex;
						align-items: center;
						color: $blue;

						&:hover {
							color: $pink;
						}

						&.dropdown-toggle {
							&::after {
								content: '\f107';
								font-size: 24px;
								font-weight: 900;
								line-height: 1;
								font-family: $font-awesome;
								margin-left: 8px;
								border: none;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $pink;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						min-width: 100%;
						padding: 0.5rem 1rem;
						border: none;
						border-radius: 10px;
						background-color: $beige-light;
						box-shadow: $box-shadow;

						.nav-item {
							font-size: 18px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $beige;

								&:hover {
									color: $pink;
								}
							}

							&.active {
								.nav-link {
									color: $pink;
								}
							}
						}
					}
				}
			}
		}

		// navbar-toggler
		.navbar-toggler {
			position: relative;
			z-index: 20;
			display: flex !important;
			margin-left: 15px;
			padding: 8px 20px;
			border-radius: 19px;
			background-color: $pink;
			color: $white;
			font-size: 20px;
			font-weight: 400;
			font-family: $font-family-secondary;
			text-transform: uppercase;
			transition: 0.5s;

			i {
				margin-right: 10px;
			}

			&:hover {
				background-color: $orange;
			}
		}

		// overlay-menu
		.column.navbar {
			.overlay-menu {
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				align-items: flex-start;

				padding: 25vh 30px;
				background-color: rgba($white, 0.9);
				overflow: scroll;

				@include media-breakpoint-up(xl) {
					display: none !important;
				}

				.navbar-nav {
					@extend .d-block;
					flex: 1 1 auto;
					width: 100%;
					max-width: 300px;
					margin: 0 auto;

					.nav-item {
						margin: 0 0 1px 0;
						font-size: 20px;
						font-family: $font-family-secondary;
						text-align: center;

						.nav-link {
							background-color: $blue-light;
							border: 2px solid $blue-light;
							border-radius: $border-radius;
							color: $blue;

							transition: $transition-base;

							&:hover {
								background-color: $pink;
								border-color: $pink;
								color: $white;
							}
						}
					}

					.dropdown {
						.dropdown-menu {
							@extend .position-relative;

							margin: 0 0 15px 0;
							padding: 15px;
							background-color: transparent;
							border: 2px solid $blue;
							border-top: 0;
							border-top-right-radius: 0;
							border-top-left-radius: 0;

							.nav-item {
								font-weight: $font-weight-base;

								.nav-link {
									padding: 0.5rem 1rem;
								}
							}
						}

						&.show {
							>.nav-link {
								border-bottom-right-radius: 0;
								border-bottom-left-radius: 0;
							}
						}
					}
				}

				&.show {
					display: flex !important;
				}
			}
		}
	}

	// sticky
	&.sticky {
		.header-main {
			.logo {
				@include media-breakpoint-up(sm) {
					max-width: 110px;
				}
			}
		}
	}
}

.header+.mini-sab {
	margin-top: 80px;

	@include media-breakpoint-up(xl) {
		margin-top: 180px;
	}
}

.header+.main {
	@include media-breakpoint-up(xl) {
		margin-top: 65px;
	}
}

&.accommodation_search_book,
&.accommodation_book {
	.header {
		position: relative !important;
	}
}
