&.accommodation_category_detail,
&.accommodation_detail {
	.accommodation_detail {
		.extra-info {
			&.full-width {
				@include make-col-ready();
				@include make-col(12);
			}
		}

		.tommy-info {
			@include make-col-ready();
			@include make-col(12);
		}

		.tommy {
			margin-bottom: 3rem;

			.w3-vue-booking-wrapper {
				.w3-filter-collection {
					margin-bottom: 1rem !important;

					.filters {
						justify-content: flex-start !important;
					}
				}
			}
		}
	}
}

&.accommodation_detail {
	.description {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 10;
		-webkit-box-orient: vertical;

		&.active {
			-webkit-line-clamp: 100;
		}
	}

	.read-more {
		position: relative;
		margin-top: 10px;
		padding-top: 3px;
		
		.btn {
			font-size: 18px;
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 80px;
			background-image: linear-gradient(rgba(#FFF, 0), rgba($body-bg, 1) 80%);
			position: absolute;
			bottom: 100%;
		}

		&.active {
			&::before {
				display: none;
			}
		}
	}
}